<template>
  <div>
    <div class="head">
      每个考点的累积得分率变化情况
      <a-date-picker class="date"
                     v-model:value="today"
                     value-format="YYYY-MM-DD"
                     @change="parmarsChange"
                     placeholder="选择日期">
      </a-date-picker>
    </div>

    <chart :series='series'
           :labels='labels'
           ref='chartRef'
           height='420rem' />
  </div>
</template>

<script setup>

import { instance } from '@/utils/http.js'
import * as echarts from 'echarts'
import { ref, watch, defineExpose, onMounted, nextTick } from 'vue'
import { getChartsHistory } from '@/api/knowledgeLine.js'
import chart from './chart.vue'
let series = ref([])
let labels = ref([])

let today = ref('')
let starts = ref([])
let colors = ['#FA7676', '#FAC376', '#76BBFA', '#7BEEC5', '#9176FA']
let chartRef = ref()
const emit = defineEmits(['parmarsChange'])
const parmarsChange = () => {
  labels.value = []
  series.value = []
  starts.value = []
  // this.$parent.parmarsChange()
  emit('parmarsChange')
}
const removeData = () => {
  // this.time = ''
  series.value = []
  labels.value = []
}
const remove = () => {
  removeData()
  chartRef.value.clear()
}
/* const clear = () => {
  let myChart = echarts.init(document.getElementById('myChartLeiji'))
  myChart.dispose()
  drawLine()
} */

//删除该知识点图表
const del = (index) => {
  series.value.splice(index, 1)
  if (series.value.length == 0) {
    labels.value = []
  } else {
    series.value.splice(index, 1)
  }
  starts.value.splice(index, 1)
  chartRef.value.clear()
}
const getData = async (id, index) => {
  if (!id) {
    series.value = []
    labels.value = []
    chartRef.value.clear()
  }

  const { data } = await getChartsHistory({
    knowledge_id: id,
    today: today.value,
    first_day: starts.value.length ? starts.value[0] : ''
  }
  )
  let data1 = {
    data: [],
    type: 'line',
    name: data.list[0].knowledge_desc,
    smooth: true
  }
  data1.data = data.list.map(item => {
    return item.score
  })
  starts.value.push(data.list[0].start_day)
  if (!labels.value.length) {
    labels.value = data.list.map(item => {
      return item.end_day
    })
  }
  series.value[index] = data1
  // series.value.push(data1)
  // drawLine()
}
onMounted(() => {
  nextTick(() => {
    chartRef.value.drawLine()
  })
})

defineExpose({
  removeData, getData, del, remove
})

</script>

<style lang="scss" scoped>
.head {
  display: flex;
  position: relative;
  justify-content: center;
  font-size: 30rem;
  font-weight: bold;
  color: #000000;
  margin-top: 20rem;
  .date {
    position: absolute;
    width: 230rem;
    right: 52rem;
  }
}
:deep .ant-picker-input > input {
  width: 100%;
  min-width: 0;
  padding: 3rem 2rem;
  font-size: 20rem;
}
:deep .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 40rem;
  line-height: 40rem;
}
</style>