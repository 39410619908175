export const rangeOptions = [
  { label: '一天', value: 1 },
  { label: '两天', value: 2 },
  { label: '三天', value: 3 },
  { label: '四天', value: 4 },
  { label: '五天', value: 5 },
  { label: '六天', value: 6 },
  { label: '一周', value: 7 },
  { label: '八天', value: 8 },
  { label: '九天', value: 9 },
  { label: '十天', value: 10 },
  { label: '十一天', value: 11 },
  { label: '十二天', value: 12 },
  { label: '十三天', value: 13 },
  { label: '两周', value: 14 },
  { label: '十五天', value: 15 },
  { label: '十六天', value: 16 },
  { label: '十七天', value: 17 },
  { label: '十八天', value: 18 },
  { label: '十九天', value: 19 },
  { label: '二十天', value: 20 },
  { label: '三周', value: 21 },
  { label: '二十二天', value: 22 },
  { label: '二十三天', value: 23 },
  { label: '二十四天', value: 24 },
  { label: '二十五天', value: 25 },
  { label: '二十六天', value: 26 },
  { label: '二十七天', value: 27 },
  { label: '四周', value: 28 },
  { label: '二十九天', value: 29 },
  { label: '三十天', value: 30 },
  { label: '三十一天', value: 31 },
]