<template>
  <div ref='chartRef'
       :style="{width: '100%', height: height}"></div>
</template>

<script setup>
import * as echarts from 'echarts'
import { ref, watch, nextTick, toRefs } from 'vue'
let props = defineProps(
  {
    series: {
      type: Array,
      default: []
    },
    labels: {
      type: Array,
      default: []
    },
    height: {
      type: String,
      default: "380rem"
    }
  }
)
let series = toRefs(props).series
let labels = toRefs(props).labels
let height = toRefs(props).height
let chartRef = ref()
let myChart
let colors = ['#FA7676', '#FAC376', '#76BBFA', '#7BEEC5', '#9176FA']
const drawLine = () => {
  if (myChart != null && myChart != "" && myChart != undefined) {
    myChart.dispose(); //销毁
  }
  myChart = echarts.init(chartRef.value)
  myChart.setOption({
    xAxis: {
      type: 'category',
      data: labels.value
    },
    legend: {
      show: false
    },
    color: colors,
    yAxis: {
      type: 'value',
      max: 100,
      min: 0,
    },
    tooltip: {
      trigger: 'axis'
    },
    grid: {
      bottom: '30rem',
      right: '10rem',
      left: '30rem'
    },
    series: series.value
  })
  window.onresize = (() => {
    myChart.resize()
  })
}
watch(series, () => {
  nextTick(() => {
    drawLine()
  })
}, { deep: true })
const clear = () => {
  myChart = myChart.dispose()
}
defineExpose({
  drawLine, clear
})
</script>

<style>
</style>