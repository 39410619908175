import { instance } from '@/utils/http.js'

// 知识点曲线-根据科目id获取知识点
export const getKnowledgeList = (params) => {
  return instance({
    url: '/api/v1/public/get_knowledge1',
    method: 'get',
    params
  })
}

// 知识点曲线-累积得分曲线数据
export const getChartsHistory = (params) => {
  return instance({
    url: '/api/v1/report/knowledge_charts_history',
    method: 'get',
    params
  })
}

// 知识点曲线-得分曲线数据
export const getCharts = (params) => {
  return instance({
    url: '/api/v1/report/knowledge_charts',
    method: 'get',
    params
  })
}